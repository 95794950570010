import { ChatHistory, ChatResponse } from "../context/aiTutorContextProvider";

interface Props {
  aiModel: string;
  conversationId: string | null;
  chatHistory: ChatHistory[];
}

async function callLakitu(
  { aiModel, conversationId, chatHistory }: Props,
  auth0Token: string,
  abortController: AbortController,
): Promise<ChatResponse> {
  const API_URL = process.env.LAKITU_CHAT_URL!;
  const headers = {
    accept: "application/json",
    authorization: `Bearer ${auth0Token}`,
    "Content-Type": "application/json",
  };

  const history = chatHistory.map((it) => ({
    role: it.role,
    content: it.content,
  }));

  const body = {
    chat_history: history,
    model: aiModel,
    conversation_id: conversationId,
  };

  const response = await fetch(API_URL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
    signal: abortController.signal,
  });

  if (response.status !== 200) {
    throw Error("api call error " + response.status);
  }

  return response.json();
}

export default callLakitu;
