import React, { useEffect, useRef, useState } from "react";
import "boclips-player/dist/main.css";
import { PlayerFactory } from "boclips-player";
import {
  BoclipsToken,
  ChatItem,
  isTokenValid,
} from "../../context/aiTutorContextProvider";

interface VideoPlayerProps {
  clip?: ChatItem;
  boclipsToken?: BoclipsToken;
}

const VideoPlayer = ({ clip, boclipsToken }: VideoPlayerProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    renderVideo();
  }, [clip, boclipsToken]);

  function getTokenFactory(token?: string): () => Promise<string> {
    return () =>
      new Promise<string>((resolve, reject) => {
        token ? resolve(token) : reject();
      });
  }

  const playerRef = useRef<HTMLDivElement>(null);

  async function renderVideo() {
    if (isLoaded) return;

    const playerContainer = playerRef.current;

    if (playerContainer && clip) {
      const player = PlayerFactory.get(playerContainer, {
        api: { tokenFactory: getTokenFactory(boclipsToken?.access_token) },
        displayAutogeneratedCaptions: true,
        interface: {
          controls: [
            "play-large",
            "restart",
            "play",
            "progress",
            "current-time",
            "duration",
            "mute",
            "volume",
            "captions",
            "settings",
            "fullscreen",
          ],
        },
      });

      const { video_id, start_time, end_time } = clip;

      if (player) {
        player.loadVideo(`https://api.boclips.com/v1/videos/${video_id}`, {
          start: start_time,
          end: end_time,
        });

        setIsLoaded(true);
      }
    }
  }

  return (
    <>
      {(isLoaded || isTokenValid(boclipsToken)) && <div ref={playerRef}></div>}
    </>
  );
};

export default VideoPlayer;
