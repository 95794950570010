import React, { useEffect, useRef, useState } from "react";
import s from "./style.module.less";
import Button from "@boclips-ui/button";
import c from "classnames";
import ThumbsUp from "../../assets/thumbs-up.svg";
import getFeedbackOptions from "../../api/getFeedbackOptions";
import {
  ChatHistory,
  useAiTutorContextProvider,
} from "../../context/aiTutorContextProvider";
import CloseOnClickOutside from "../../hooks/closeOnClickOutside";
import sendVideoFeedback from "../../api/sendVideoFeedback";

interface Props {
  children: React.ReactNode;
  clipId: string;
}

interface FeedbackAPI {
  positive: { [key: string]: string };
  negative: { [key: string]: string };
}

const FeedbackButton = ({
  chatHistory,
  conversationId,
  clipId,
  type,
  feedbackOptions,
}: {
  chatHistory: ChatHistory[];
  conversationId: string | null;
  clipId: string;
  type: "positive" | "negative";
  feedbackOptions?: FeedbackAPI;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonWrapperRef = useRef(null);
  const handleFeedbackApiResponse = feedbackOptions
    ? Object.entries(feedbackOptions[type]).map(([key, value]) => {
        return {
          id: key,
          text: value,
        };
      })
    : [];

  const { auth0Token } = useAiTutorContextProvider();

  const handleOnClick = ({
    id,
    message,
    clipId,
  }: {
    [key: string]: string;
  }) => {
    const request = {
      chatHistory: chatHistory,
      conversation_id: conversationId,
      feedback_message: message,
      clip_id: clipId,
      selected_option_id: id,
    };

    sendVideoFeedback({ request, auth0Token });
    setIsOpen(false);
  };

  CloseOnClickOutside(buttonWrapperRef, () => setIsOpen(false));

  return (
    <div ref={buttonWrapperRef}>
      <Button
        width="52px"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={c({
          [s.active]: isOpen,
          [s.positive]: type === "positive",
          [s.negative]: type === "negative",
        })}
        iconOnly
        icon={<ThumbsUp />}
        type="outline"
        text=""
      />
      {isOpen && (
        <ul
          className={c({
            [s.positive]: type === "positive",
            [s.negative]: type === "negative",
          })}
        >
          {handleFeedbackApiResponse.map((it) => {
            return (
              <li key={it.id}>
                <Button
                  onClick={() =>
                    handleOnClick({
                      id: it.id,
                      message: it.text,
                      clipId,
                    })
                  }
                  text={it.text}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const Feedback = ({ children, clipId }: Props) => {
  const [feedbackOption, setFeedbackOptions] = useState<FeedbackAPI>();
  const { auth0Token, chatHistory, conversationId } =
    useAiTutorContextProvider();

  useEffect(() => {
    getFeedbackOptions(auth0Token).then((it) => {
      setFeedbackOptions(it);
    });
  }, [auth0Token]);

  return (
    <div className={s.playerWrapper}>
      {children}
      <span className={s.buttonWrapper}>
        <FeedbackButton
          chatHistory={chatHistory}
          conversationId={conversationId}
          clipId={clipId}
          feedbackOptions={feedbackOption}
          type="positive"
        />
        <FeedbackButton
          chatHistory={chatHistory}
          conversationId={conversationId}
          clipId={clipId}
          feedbackOptions={feedbackOption}
          type="negative"
        />
      </span>
    </div>
  );
};

export default Feedback;
