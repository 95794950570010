// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profilePictureWrapper--RbQ2l {
  width: 30px;
  height: 30px;
}
.profilePictureWrapper--RbQ2l img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/components/userProfile/style.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAHA;EAKI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,cAAA;AACJ","sourcesContent":[".profilePictureWrapper {\n  width: 30px;\n  height: 30px;\n\n  img {\n    width: 100%;\n    height: 100%;\n    border-radius: 50px;\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profilePictureWrapper": `profilePictureWrapper--RbQ2l`
};
export default ___CSS_LOADER_EXPORT___;
