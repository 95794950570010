// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptionWrapper--HsYMa {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1A202C;
}
@media screen and (max-width: 768px) {
  .descriptionWrapper--HsYMa {
    display: none;
  }
}
.descriptionWrapper--HsYMa > div {
  margin-bottom: 0.5rem;
  text-align: center;
}
.descriptionWrapper--HsYMa .introIllustration--bGYjo {
  margin-top: 4rem;
  width: 100%;
  height: auto;
  max-width: 420px;
  margin-bottom: 3rem;
}
@media screen and (max-height: 900px) {
  .descriptionWrapper--HsYMa .introIllustration--bGYjo {
    max-width: 300px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/chatbotDescription/style.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;AADF;AAGE;EAAA;IACE,aAAA;EAAF;AACF;AAEE;EACE,qBAAA;EACA,kBAAA;AAAJ;AAZA;EAgBI,gBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AAGI;EAAA;IACE,gBAAA;EAAJ;AACF","sourcesContent":["@import \"~@boclips-ui/styles/src/colors\";\n\n.descriptionWrapper {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  color: @gray-900;\n\n  @media screen and (max-width: 768px) {\n    display: none;\n  }\n\n  & > div {\n    margin-bottom: 0.5rem;\n    text-align: center;\n  }\n\n  .introIllustration {\n    margin-top: 4rem;\n    width: 100%;\n    height: auto;\n    max-width: 420px;\n    margin-bottom: 3rem;\n\n    @media screen and (max-height: 900px) {\n      max-width: 300px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"descriptionWrapper": `descriptionWrapper--HsYMa`,
	"introIllustration": `introIllustration--bGYjo`
};
export default ___CSS_LOADER_EXPORT___;
