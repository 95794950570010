import React, { useEffect, useRef } from "react";
import s from "./style.module.less";
import {
  ChatHistory,
  ChatItem,
  useAiTutorContextProvider,
} from "../../context/aiTutorContextProvider";
import VideoPlayer from "../videoPlayer";
import Markdown from "react-markdown";
import { Typography } from "@boclips-ui/typography";
import c from "classnames";
import Feedback from "../feedback/Feedback";
// @ts-ignore
import ChatbotDescription from "../chatbotDescription";

const ChatArea = () => {
  const { chatHistory, isLoading, boclipsToken } = useAiTutorContextProvider();

  const chatWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatWrapperRef.current) {
      const penultimateElement =
        chatWrapperRef.current.children[
          chatWrapperRef.current.children.length - 2
        ];

      if (penultimateElement) {
        penultimateElement.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    }
  }, [chatHistory]);

  const handleAnswerWithClips = (item: ChatHistory) => {
    const { content, clips } = item;
    const regex = /\[BOVIDEO: (\w+)\]/g;
    let lastIndex = 0;
    const result: Array<string | ChatItem> = [];

    content.replace(regex, (substring, clipId, index) => {
      result.push(content.substring(lastIndex, index));

      if (clipId && clips) {
        clips[clipId].clip_id = clipId;
        result.push(clips[clipId]);
      }

      lastIndex = index + substring.length;
      return substring;
    });

    // Add the remaining part of the content after the last match
    if (lastIndex < content.length) {
      result.push(content.substring(lastIndex));
    }

    return result;
  };

  return (
    <section ref={chatWrapperRef} className={s.chatWrapper} id="chatWrapper">
      {chatHistory.length === 0 && <ChatbotDescription />}
      <div>
        {chatHistory.map((item, index) => {
          if (item.role === "user") {
            return (
              <div className={s.chatItem} key={index}>
                <Typography.Title2>You</Typography.Title2>
                <Typography.Body className={s.question}>
                  <p>{item.content}</p>
                </Typography.Body>
              </div>
            );
          } else if (item.role === "assistant") {
            if (item.clips === null) {
              return (
                <div className={s.chatItem} key={index}>
                  <Typography.Title1>Boclips</Typography.Title1>
                  <Typography.Body className={s.answer}>
                    <Markdown>{item.content}</Markdown>
                  </Typography.Body>
                </div>
              );
            }

            const responseWithVideos = handleAnswerWithClips(item);

            return (
              <div className={s.chatItem} key={index}>
                <Typography.Title1>Boclips (beta)</Typography.Title1>
                <div className={s.answer}>
                  {responseWithVideos.map((it: string | ChatItem, index) => {
                    if (typeof it === "string") {
                      return (
                        <Typography.Body className={s.answer} key={index}>
                          <Markdown>{it}</Markdown>
                        </Typography.Body>
                      );
                    } else {
                      return (
                        <Feedback key={index} clipId={it.clip_id!}>
                          <VideoPlayer
                            clip={it}
                            boclipsToken={boclipsToken}
                            key={it.video_id}
                          />
                        </Feedback>
                      );
                    }
                  })}
                </div>
              </div>
            );
          }
        })}
        {isLoading && (
          <div className={c(s.chatItem, s.skeleton)} key={"Loading"}>
            <Typography.Title1>Boclips (beta)</Typography.Title1>
            <Typography.Body className={s.answer} />
          </div>
        )}
      </div>
    </section>
  );
};

export default ChatArea;
