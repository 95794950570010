import { InputText } from "@boclips-ui/input";
import Dropdown, { OptionsProps } from "@boclips-ui/dropdown";
import { Typography } from "@boclips-ui/typography";
import React, { useEffect, useState } from "react";
import sendRegistrationFormToLakitu, {
  LakituFormData,
} from "../../api/sendRegistrationFormToLakitu";
import { useAiTutorContextProvider } from "../../context/aiTutorContextProvider";
import callLakituForCompanySegments from "../../api/callLakituForCompanySegments";
import { Bodal } from "../bodal/Bodal";
import RegistrationPageCheckbox from "../registrationPageCheckbox/RegistrationPageCheckbox";
import s from "./style.module.less";

const RegisterBodal = ({
  firstName,
  lastName,
  setIsOpen,
}: {
  firstName?: string;
  lastName?: string;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const [formFields, setFormFields] = useState<LakituFormData>({
    first_name: firstName || "",
    last_name: lastName || "",
    job_title: "",
    company_name: "",
    company_segment: "",
    agree_contact: false,
  });
  const [errors, setErrors] = useState<LakituFormData>();
  const [companySegments, setCompanySegments] = useState<OptionsProps[]>([]);
  const { auth0Token } = useAiTutorContextProvider();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (auth0Token) {
      callLakituForCompanySegments(auth0Token).then((segments) => {
        const options = Object.values(segments).map((it) => ({
          id: it as string,
          name: it as string,
          value: it as string,
        }));
        setCompanySegments(options);
      });
    }
  }, [auth0Token]);

  const validateForm = () => {
    // @ts-ignore
    const newErrors: LakituFormData = {};

    if (!formFields.first_name) newErrors.first_name = "First name is required";
    if (!formFields.last_name) newErrors.last_name = "Last name is required";
    if (!formFields.job_title) newErrors.job_title = "Job title is required";
    if (!formFields.company_segment)
      newErrors.company_segment = "Company segment is required";
    if (!formFields.company_name)
      newErrors.company_name = "Company name is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const onFormChange = (value: {
    [key: string]: string | boolean | string[];
  }) => {
    setFormFields((prevState) => ({ ...prevState, ...value }));
  };

  const onSubmit = () => {
    if (!validateForm()) return;
    setIsLoading(true);
    sendRegistrationFormToLakitu(formFields, auth0Token).finally(() => {
      setIsLoading(false);
      setIsOpen(false);
    });
  };

  return (
    <Bodal
      title="Tell us more about yourself!"
      onConfirm={onSubmit}
      displayCancelButton={false}
      isLoading={isLoading}
      confirmButtonText="Sign Up"
      footerText={
        <div className={s.footerText}>
          <Typography.Body size="small">
            By clicking Sign Up, you agree to our{" "}
            <a
              rel="noopener noreferrer"
              href="https://www.boclips.com/chatbot-demo-terms-of-use"
              target="_blank"
            >
              <Typography.Link type="inline-blue">
                Boclips Terms & Conditions
              </Typography.Link>
            </a>
            , have read out{" "}
            <a
              rel="noopener noreferrer"
              href="https://www.boclips.com/privacy-policy"
              target="_blank"
            >
              <Typography.Link type="inline-blue">
                Privacy Policy
              </Typography.Link>
            </a>{" "}
            and confirm you understand that this app is intended for
            demonstration and educational use.
          </Typography.Body>
        </div>
      }
    >
      <div className={s.formWrapper}>
        <div className={s.row}>
          <InputText
            labelText="First name"
            id="first_name"
            onChange={(value) => onFormChange({ first_name: value })}
            isError={errors && !!errors.first_name}
            inputType="text"
            placeholder="John"
            defaultValue={formFields.first_name}
          />
          <InputText
            labelText="Last name"
            id="last_name"
            onChange={(value) => onFormChange({ last_name: value })}
            isError={errors && !!errors.last_name}
            inputType="text"
            placeholder="Smith"
            defaultValue={formFields.last_name}
          />
        </div>

        <InputText
          labelText="Job title"
          id="job_title"
          onChange={(value) => onFormChange({ job_title: value })}
          isError={errors && !!errors.job_title}
          inputType="text"
          placeholder="Teacher"
        />

        <InputText
          labelText="Company name"
          id="company_name"
          onChange={(value) => onFormChange({ company_name: value })}
          isError={errors && !!errors.company_name}
          inputType="text"
          placeholder="Awesome company"
        />

        <Dropdown
          placeholder="Select company segment"
          onUpdate={(value) => onFormChange({ company_segment: value })}
          errorMessagePlacement="bottom"
          mode="single"
          options={companySegments}
          fitWidth
          labelText="Company segment"
          showLabel
          isError={errors && !!errors.company_segment}
          errorMessage=""
        />

        <InputText
          labelText="How did you hear about us?"
          id="how_did_you_hear_about_us"
          onChange={(value) =>
            onFormChange({ how_did_you_hear_about_us: value })
          }
          isError={errors && !!errors.company_name}
          inputType="text"
          placeholder="World wide web..."
        />

        <div className={s.checkboxSection}>
          <RegistrationPageCheckbox
            onChange={(e) =>
              onFormChange({ agree_contact: e.currentTarget.checked })
            }
            name="boclips-terms-conditions-agreement"
            id="boclips-terms-conditions-agreement"
            checked={!!formFields?.agree_contact}
            dataQa="input-checkbox-boclips-terms-conditions"
            isError={errors && errors.agree_contact}
            label={
              <Typography.Body size="small" weight="medium">
                Boclips can contact me about their products and services through
                email
              </Typography.Body>
            }
          />
        </div>
      </div>
    </Bodal>
  );
};

export default RegisterBodal;
