async function callLakituForBoclipsToken(auth0Token: string) {
  const API_URL = process.env.LAKITU_URL_FOR_BOCLIPS_TOKEN!;
  const headers = {
    accept: "application/json",
    authorization: `Bearer ${auth0Token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(API_URL, {
      method: "GET",
      headers: headers,
    });
    if (!response.ok) {
      throw new Error(`API call error: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default callLakituForBoclipsToken;
