export interface LakituFormData {
  first_name: string;
  last_name: string;
  job_title: string;
  company_name: string;
  company_segment: string;
  how_did_you_hear_about_us?: string;
  agree_contact?: boolean;
}

async function sendRegistrationFormToLakitu(
  data: LakituFormData,
  auth0Token: string,
) {
  const API_URL = process.env.LAKITU_REGISTRATION_URL;

  const headers = {
    accept: "application/json",
    authorization: `Bearer ${auth0Token}`,
    "Content-Type": "application/x-www-form-urlencoded",
  };

  const body = Object.keys(data)
    // @ts-ignore
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

  try {
    const response = await fetch(API_URL as string, {
      method: "POST",
      headers: headers,
      body: body,
    });
    const data = response.json();
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }

  // return response.json();
}

export default sendRegistrationFormToLakitu;
