const getFeedbackOptions = async (auth0Token: string) => {
  const URL = process.env.LAKITU_GET_VIDEO_FEEDBACK_OPTIONS_URL!;

  const headers = {
    accept: "application/json",
    authorization: `Bearer ${auth0Token}`,
    "x-boclips-api-version": "v2",
  };

  const response = await fetch(URL, {
    method: "GET",
    headers: headers,
  });

  const data = await response.json();

  return data;
};

export default getFeedbackOptions;
