// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modelSelect--Q8J_3 {
  width: 100%;
  background: #F9FBFF;
  height: 230px;
  padding: 1rem;
}
.modelSelect--Q8J_3 .logo--yAn6k {
  padding: 1rem;
  max-width: 200px;
  margin-bottom: 1rem;
}
.modelSelect--Q8J_3 button {
  height: 75px !important;
}
.modelSelect--Q8J_3 ul[role="listbox"] {
  top: 75px !important;
}
@media (max-width: 768px) {
  .modelSelect--Q8J_3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 90px;
    width: auto;
    order: 1;
    flex-grow: 1;
  }
  .modelSelect--Q8J_3 .logo--yAn6k {
    height: auto;
    width: 100%;
    max-width: 125px;
    margin: 0;
    padding: 0 1rem;
  }
  .modelSelect--Q8J_3 > span {
    display: none;
  }
}
.dropdownItem--dBtJH {
  display: flex;
  flex-direction: column;
}
.dropdownItem--dBtJH span {
  text-align: left;
}
.dropdownItem--dBtJH span {
  color: #1A202C;
}
.dropdownItem--dBtJH span:last-child {
  padding-top: 0.25rem;
  font-size: 0.8rem;
  color: #2D3748;
}
`, "",{"version":3,"sources":["webpack://./src/components/modelSelect/style.module.less"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;AADF;AAHA;EAOI,aAAA;EACA,gBAAA;EACA,mBAAA;AADJ;AARA;EAaI,uBAAA;AAFJ;AAXA;EAiBI,oBAAA;AAHJ;AAOA;EACE;IACE,aAAA;IACA,mBAAA;IACA,mBAAA;IACA,gBAAA;IACA,WAAA;IACA,QAAA;IACA,YAAA;EALF;EAFA;IAUI,YAAA;IACA,WAAA;IACA,gBAAA;IACA,SAAA;IACA,eAAA;EALJ;EATA;IAkBI,aAAA;EANJ;AACF;AAUA;EACE,aAAA;EACA,sBAAA;AARF;AAMA;EAKI,gBAAA;AARJ;AAGA;EAQI,cAAA;AARJ;AAUI;EACE,oBAAA;EACA,iBAAA;EACA,cAAA;AARN","sourcesContent":["@import \"~@boclips-ui/styles/src/colors\";\n\n.modelSelect {\n  width: 100%;\n  background: @blue-100;\n  height: 230px;\n  padding: 1rem;\n\n  .logo {\n    padding: 1rem;\n    max-width: 200px;\n    margin-bottom: 1rem;\n  }\n\n  button {\n    height: 75px !important;\n  }\n\n  ul[role=\"listbox\"] {\n    top: 75px !important;\n  }\n}\n\n@media (max-width: 768px) {\n  .modelSelect {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    max-height: 90px;\n    width: auto;\n    order: 1;\n    flex-grow: 1;\n\n    .logo {\n      height: auto;\n      width: 100%;\n      max-width: 125px;\n      margin: 0;\n      padding: 0 1rem;\n    }\n\n    > span {\n      display: none;\n    }\n  }\n}\n\n.dropdownItem {\n  display: flex;\n  flex-direction: column;\n\n  span {\n    text-align: left;\n  }\n  span {\n    color: @gray-900;\n\n    &:last-child {\n      padding-top: 0.25rem;\n      font-size: 0.8rem;\n      color: @gray-800;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modelSelect": `modelSelect--Q8J_3`,
	"logo": `logo--yAn6k`,
	"dropdownItem": `dropdownItem--dBtJH`
};
export default ___CSS_LOADER_EXPORT___;
