// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formWrapper--JpB7s {
  display: flex;
  flex-direction: column;
}
.formWrapper--JpB7s label {
  margin-bottom: 0.5rem;
  color: #2D3748;
}
.formWrapper--JpB7s label[for="how_did_you_hear_about_us"] {
  margin-top: 0.5rem;
}
.formWrapper--JpB7s input {
  height: 42px;
  font-size: 1rem;
}
.formWrapper--JpB7s .row--XTWLj {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
}
.formWrapper--JpB7s .row--XTWLj label {
  flex-grow: 1;
}
.formWrapper--JpB7s .checkboxSection--rQ7L_ {
  margin-top: 1rem;
}
.footerText--UL2Tx {
  padding: 0.5rem;
  text-align: center;
}
.footerText--UL2Tx * {
  font-size: 12px !important;
}
.illustration--MzDZ4 {
  zoom: 0.5;
}
`, "",{"version":3,"sources":["webpack://./src/components/registrationBodal/style.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AADA;EAKI,qBAAA;EACA,cAAA;AADJ;AALA;EAUI,kBAAA;AAFJ;AARA;EAcI,YAAA;EACA,eAAA;AAHJ;AAZA;EAmBI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,WAAA;AAJJ;AAlBA;EAyBM,YAAA;AAJN;AArBA;EA8BI,gBAAA;AANJ;AAUA;EACE,eAAA;EACA,kBAAA;AARF;AAMA;EAII,0BAAA;AAPJ;AAWA;EACE,SAAA;AATF","sourcesContent":["@import \"~@boclips-ui/styles/src/colors\";\n\n.formWrapper {\n  display: flex;\n  flex-direction: column;\n\n  label {\n    margin-bottom: 0.5rem;\n    color: @gray-800;\n  }\n\n  label[for=\"how_did_you_hear_about_us\"] {\n    margin-top: 0.5rem;\n  }\n\n  input {\n    height: 42px;\n    font-size: 1rem;\n  }\n\n  .row {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    gap: 0.5rem;\n\n    label {\n      flex-grow: 1;\n    }\n  }\n\n  .checkboxSection {\n    margin-top: 1rem;\n  }\n}\n\n.footerText {\n  padding: 0.5rem;\n  text-align: center;\n  * {\n    font-size: 12px !important;\n  }\n}\n\n.illustration {\n  zoom: 0.5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `formWrapper--JpB7s`,
	"row": `row--XTWLj`,
	"checkboxSection": `checkboxSection--rQ7L_`,
	"footerText": `footerText--UL2Tx`,
	"illustration": `illustration--MzDZ4`
};
export default ___CSS_LOADER_EXPORT___;
