import React from "react";
import { createRoot } from "react-dom/client";
import App from "../src/App";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { AiTutorContextProvider } from "./context/aiTutorContextProvider";
import Pendo = pendo.Pendo;

declare global {
  interface Window {
    pendo: Pendo;
  }
}

Sentry.init({
  dsn: "https://22d10591c0e53bd0eecb7a05699b5a85@o236297.ingest.sentry.io/4506637192658944",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        "localhost",
        /^https:\/\/tutor\.boclips-labs\.com/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.ENV,
});

const container = document.getElementById("root")!;

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="auth.boclips.com"
        clientId="mXa1k1ck3TQDj3g10QmYKxDOgTYvYgTe"
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: "https://lakitu-api-ewemvrrzba-ez.a.run.app",
        }}
      >
        <AiTutorContextProvider>
          <App />
        </AiTutorContextProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
);
