import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import s from "./style.module.less";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (user) {
    return (
      isAuthenticated && (
        <div className={s.profilePictureWrapper}>
          <img src={user.picture} alt={user.name} title={user.name} />
        </div>
      )
    );
  } else {
    return <p>User not found.</p>;
  }
};

export default Profile;
