// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttonWrapper--eBPUN {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/login/style.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AACF","sourcesContent":[".buttonWrapper {\n  display: flex;\n  flex-direction: row;\n  gap: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonWrapper": `buttonWrapper--eBPUN`
};
export default ___CSS_LOADER_EXPORT___;
