import React, { useEffect, useRef, useState } from "react";
import s from "./style.module.less";
import callLakitu from "../../api/callLakitu";
import {
  ChatHistory,
  ChatResponse,
  ErrorResponse,
  useAiTutorContextProvider,
} from "../../context/aiTutorContextProvider";
import { Typography } from "@boclips-ui/typography";
import Button from "@boclips-ui/button";
import SendIcon from "../../assets/send.svg";
import LoadingDots from "../../assets/dots.svg";
import { getRandomQuestions } from "./promoQuestions";

const QuestionInput = () => {
  const [inputValue, setInputValue] = useState("");
  const [randomQuestion, setRandomQuestions] = useState<string[]>([]);

  const {
    aiModelForLakitu,
    conversationId,
    setConversationId,
    chatHistory,
    setChatHistory,
    auth0Token,
    lakituError,
    setLakituError,
    isLoading,
    setIsLoading,
  } = useAiTutorContextProvider();

  const errorRef = useRef<HTMLDivElement>(null);
  const abortController = useRef(new AbortController());

  const saveUserInputToChatHistory = (inputValue: string): ChatHistory[] => {
    const userInput: ChatHistory = {
      role: "user",
      content: inputValue,
    };

    setChatHistory((prevState: ChatHistory[]) => [...prevState, userInput]);

    return [...chatHistory, userInput];
  };

  const saveAssistantResponseToChatHistory = (response: ChatResponse) => {
    const assistantInput: ChatHistory = {
      role: "assistant",
      content: response.answer,
      clips: response.clips,
    };

    setChatHistory((prevState: ChatHistory[]) => [
      ...prevState,
      assistantInput,
    ]);
  };

  function getConversationId(conversationId: string | null) {
    if (!conversationId) {
      const id = new Date().getTime().toString();
      setConversationId(id);
      return id;
    }
    return conversationId;
  }

  async function sendQuestionToLakitu(history: ChatHistory[]) {
    lakituError && setLakituError(undefined);

    const id = getConversationId(conversationId);

    if (aiModelForLakitu !== "") {
      setInputValue("");

      try {
        const response: ChatResponse = await callLakitu(
          {
            aiModel: aiModelForLakitu!,
            conversationId: id,
            chatHistory: history,
          },
          auth0Token,
          abortController.current,
        );

        saveAssistantResponseToChatHistory(response);
      } catch (e) {
        let message = "Unknown Error";
        if (e instanceof Error) message = e.message;
        if (!abortController.current.signal.aborted) return;

        setLakituError({ detail: message } as ErrorResponse);
      }
    }

    setIsLoading(false);
  }

  const onSubmit = (suggestion?: string) => {
    if (inputValue.length === 0 && !suggestion) return;
    setIsLoading(true);

    lakituError && setLakituError(undefined);
    const history = saveUserInputToChatHistory(suggestion || inputValue);
    sendQuestionToLakitu(history);
    setInputValue("");
  };

  const onChange = (value: string) => {
    setInputValue(value);
  };

  const handleReset = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
    setInputValue("");
    setChatHistory([]);
    setConversationId("");
    setIsLoading(false);
    lakituError && setLakituError(undefined);
  };

  /* eslint-disable */
  const handleTextAre = (e: any) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.target.rows = e.target.rows + 1;
      return;
    }

    if (e.key === "Enter") {
      e.preventDefault();
      if (isLoading) return;
      onSubmit();
    }
  };

  const handleSuggestion = (e: any) => {
    onSubmit(e.currentTarget.innerText);
  };
  /* eslint-enable */

  useEffect(() => {
    const getQuestion = getRandomQuestions();
    setRandomQuestions(getQuestion);
  }, []);

  return (
    <main className={s.promptWrapper}>
      {chatHistory.length === 0 ? (
        <ul className={s.testQuestions}>
          {randomQuestion.map((it) => {
            return (
              <Typography.Body onClick={handleSuggestion} as="li" key={it}>
                {it}
              </Typography.Body>
            );
          })}
        </ul>
      ) : (
        <Button
          type="outline"
          className={s.restartButton}
          width="auto"
          onClick={handleReset}
          text="Restart Conversation"
        />
      )}

      {lakituError && (
        <div className={s.errorWrapper}>
          <Typography.Body as="p" ref={errorRef} className={s.errorMessage}>
            Sorry, something went wrong with this request, please try again.
          </Typography.Body>
          <button
            onClick={() => sendQuestionToLakitu(chatHistory)}
            className={s.retryButton}
          >
            Retry
          </button>
        </div>
      )}

      <div className={s.textAreaWrapper}>
        <textarea
          id="prompt-textarea"
          rows={1}
          placeholder="Message Boclips chatbot…"
          className={s.prompt}
          /* eslint-disable */
          onKeyDown={(e: any) => handleTextAre(e)}
          /* eslint-enable */
          value={inputValue}
          onChange={(e) => onChange(e.currentTarget.value)}
        />
        <Button
          onClick={() => onSubmit()}
          text=""
          iconOnly
          disabled={isLoading}
          icon={isLoading ? <LoadingDots /> : <SendIcon />}
          width={isLoading ? "80px" : "60px"}
          className={s.expand}
        />
      </div>

      <Typography.Body as="p" size="small" style={{ textAlign: "center" }}>
        Boclips chatbot and video highlights are in beta and powered by
        generative AI and so may make mistakes. Please review important
        information.
      </Typography.Body>
    </main>
  );
};

export default QuestionInput;
