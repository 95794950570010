export function getRandomQuestions(): string[] {
  const questions = [
    "Can cats see infrared?",
    "What is the weather cycle?",
    "What was the impact of the Silk Road on the world today?",
    "Can bees surf?",
    "What are leadership styles?",
    "Is there a connection between philosophy and mathematics?",
    "How do plants communicate?",
    "What is baking soda?",
    "How do you find an exoplanet?",
    "What's the difference between an emperor and a king?",
    "What are the origins of algebra?",
    "What is the legacy of Toni Morrison?",
    "Why is soil in the water cycle?",
    "Why isn't Pluto a planet anymore?",
    "Which planets have rings?",
    "Have scientists successfully cloned animals?",
    "How are synthetic diamonds made?",
    "What is a polynomial function?",
    "What makes a great poem?",
    "Who invented the newspaper?",
    "What modern English words come from Shakespeare?",
    "Is coffee good for you?",
    "How does the human body regenerate skin?",
    "What causes the Northern Lights?",
    "How do vaccines work?",
    "Do animals dream?",
    "Is nuclear energy safe?",
    "How does a compass work?",
    "What is renewable energy?",
    "How do optical illusions work?",
    "What caused the Dust Bowl?",
    "How did humans discover fermentation?",
    "Why aren't fungi considered plants?",
    "Do tongue twisters help us learn language?",
    "Why are electric vehicles expensive?",
    "Who discovered DNA?",
    "How were vaccines invented?",
    "Who was the first surgeon?",
    "What's distinctive about American literature?",
    "How many languages are spoken in Europe?",
    "How does the brain make decisions?",
    "Can athletics make you smarter?",
    "What is selfhood?",
    "What is a universal human right?",
    "Why is there so much excitement about blockchain?",
    "What are the pros and cons of social media?",
    "Who are the most famous women in STEM?",
    "What is the science behind acupuncture?",
    "Does the stock market impact the economy?",
    "How long does it take to learn a language?",
    "How do different cultures define happiness?",
    "What's the difference between an emperor and a king?",
    "How do wind turbines generate electricity?",
    "Are microplastics dangerous to marine life?",
    "How is glass made?",
  ];

  for (let i = questions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [questions[i], questions[j]] = [questions[j], questions[i]];
  }

  return questions.slice(0, 4);
}
