import React from "react";
import s from "./style.module.less";
import { Typography } from "@boclips-ui/typography";
import Illustration from "../../assets/chat-intro.svg";

function ChatbotDescription() {
  return (
    <div className={s.descriptionWrapper}>
      <Illustration className={s.introIllustration} />
      <Typography.Title2 as="div">
        This chatbot is demonstrating how video highlights from Boclips can
        enhance an AI Tutor or gen-AI experience
      </Typography.Title2>
      <Typography.Body>
        These highlights are designed for educational use in high school and
        higher education
      </Typography.Body>
    </div>
  );
}

export default ChatbotDescription;
