import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useRef,
} from "react";
import Button from "@boclips-ui/button";
import { useMediaBreakPoint } from "@boclips-ui/use-media-breakpoints";

import FocusTrap from "focus-trap-react";
import { Typography } from "@boclips-ui/typography";
import c from "classnames";
import s from "./style.module.less";

export interface Props {
  title: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmButtonText?: string;
  isLoading?: boolean;
  cancelButtonText?: string;
  dataQa?: string;
  initialFocusRef?: React.RefObject<HTMLElement> | string;
  closeOnClickOutside?: boolean;
  children: React.ReactNode;
  confirmButtonIcon?: React.ReactElement;
  displayCancelButton?: boolean;
  smallSize?: boolean;
  showFooter?: boolean;
  showCloseIcon?: boolean;
  footerClass?: string;
  footerText?: ReactElement | string;
}

export const Bodal: React.FC<Props> = ({
  title,
  onConfirm,
  confirmButtonText = "Confirm",
  dataQa = "modal",
  children,
  initialFocusRef,
  displayCancelButton = true,
  smallSize = true,
  showFooter = true,
  isLoading = false,
  footerClass,
  footerText,
}: PropsWithChildren<Props>) => {
  const breakpoints = useMediaBreakPoint();
  const mobileView = breakpoints.type === "mobile";

  const ref = useRef(null);

  useEffect(() => {
    if (typeof initialFocusRef === "string") {
      document.getElementById(initialFocusRef as string)?.focus();
    } else {
      initialFocusRef?.current!.focus();
    }
  }, [initialFocusRef]);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <FocusTrap>
      <div
        role="dialog"
        aria-labelledby="bodal-title"
        data-qa={dataQa}
        className={s.modalWrapper}
        aria-describedby="bodal-description"
      >
        <div id="bodal-description" hidden>
          This is a dialog for {title}. Escape will cancel and close the window.
        </div>
        <div className={c(s.modal, { [s.small]: smallSize })} ref={ref}>
          <div className={s.modalContent}>
            <div className={s.modalHeader}>
              {mobileView && <span />}
              <Typography.H1
                size="sm"
                className="text-gray-900"
                id="bodal-title"
              >
                {title}
              </Typography.H1>
            </div>
            {children}
          </div>
          {showFooter && (
            <div
              className={c(
                s.modalFooter,
                displayCancelButton ? [s.twoButtonFooter] : [s.oneButtonFooter],
                footerClass,
              )}
            >
              <Button
                onClick={onConfirm}
                disabled={isLoading}
                text={confirmButtonText}
              />
            </div>
          )}
          {footerText}
        </div>
      </div>
    </FocusTrap>
  );
};
