import c from "classnames";
import s from "./style.module.less";
import { Typography } from "@boclips-ui/typography";
import Button from "@boclips-ui/button";
import React from "react";
import { useAiTutorContextProvider } from "../../context/aiTutorContextProvider";

const Posters = () => {
  const { chatHistory } = useAiTutorContextProvider();

  return (
    <div className={c(s.posters, { [s.hide]: chatHistory.length > 0 })}>
      <aside>
        <Typography.Body as="p" className={s.marketingCopy} size="small">
          Video highlights uses an AI chatbot to surface curated educational
          videos from more than 550 premium brands, like PBS, SciShow, TED, and
          more.
        </Typography.Body>
        <Typography.Body as="p" className={s.marketingCopy} size="small">
          Explore more than 2.2M Ed-Ready videos in Boclips.
        </Typography.Body>
        <Typography.Title2 as="h1">For teachers and schools:</Typography.Title2>
        <a
          href="https://app.boclips.com/classroom/register"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            height="48px"
            width="100%"
            onClick={() => () => null}
            text="Boclips Classroom"
          />
        </a>
        <Typography.Title2 as="h1">
          For edtechs and product teams:
        </Typography.Title2>
        <a
          href="https://www.boclips.com/integrate"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            height="48px"
            width="100%"
            onClick={() => () => null}
            text="Boclips Integrate"
          />
        </a>
        <Typography.Title2 as="h1">
          For publishers and instructional designers:
        </Typography.Title2>
        <a
          href="https://www.boclips.com/library"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            height="48px"
            width="100%"
            onClick={() => () => null}
            text="Boclips Library"
          />
        </a>
      </aside>
    </div>
  );
};

export default Posters;
