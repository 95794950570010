import React from "react";
import "./style.less";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./views/login";
import Home from "./views/home";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default App;
