// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoutWrapper--M43sc {
  width: 100%;
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.logoutWrapper--M43sc > span {
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  color: #1A202C;
}
.logoutWrapper--M43sc > div {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .logoutWrapper--M43sc {
    order: 1;
    width: 160px;
    height: 90px;
  }
  .logoutWrapper--M43sc > span {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/userPanel/style.module.less"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,oBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;AAFF;AAHA;EAQI,qBAAA;EACA,iBAAA;EACA,cAAA;AAFJ;AARA;EAcI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,mBAAA;EACA,uBAAA;AAHJ;AAOA;EACE;IACE,QAAA;IACA,YAAA;IACA,YAAA;EALF;EAEA;IAMI,aAAA;EALJ;AACF","sourcesContent":["@import \"~@boclips-ui/styles/src/colors\";\n@box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n\n.logoutWrapper {\n  width: 100%;\n  padding: 1rem 0.5rem;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n\n  > span {\n    margin-bottom: 0.5rem;\n    font-size: 0.8rem;\n    color: @gray-900;\n  }\n\n  > div {\n    display: flex;\n    flex-direction: row;\n    gap: 0.5rem;\n    align-items: center;\n    justify-content: center;\n  }\n}\n\n@media (max-width: 768px) {\n  .logoutWrapper {\n    order: 1;\n    width: 160px;\n    height: 90px;\n\n    > span {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoutWrapper": `logoutWrapper--M43sc`
};
export default ___CSS_LOADER_EXPORT___;
