import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@boclips-ui/button";
import s from "./style.module.less";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={s.buttonWrapper}>
      <Button
        type="outline"
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "login",
            },
          })
        }
        text="Log In"
        height="48px"
      />

      <Button
        onClick={() =>
          loginWithRedirect({
            authorizationParams: {
              screen_hint: "signup",
            },
          })
        }
        text="Try it for free"
        height="48px"
      />
    </div>
  );
};

export default LoginButton;
