import React, { useEffect, useState } from "react";
import s from "./style.module.less";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import c from "classnames";
import { useAiTutorContextProvider } from "../../context/aiTutorContextProvider";
import ModelSelect from "../../components/modelSelect";
import Footer from "../../components/footer/Footer";
import RegisterBodal from "../../components/registrationBodal";
import Posters from "../../components/posters";
import UserPanel from "../../components/userPanel";
import ChatArea from "../../components/chatArea";
import QuestionInput from "../../components/questionInput";

const Home = () => {
  const { isLoading, isAuthenticated, user } = useAuth0();
  const { chatHistory } = useAiTutorContextProvider();
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (user && !user?.app_metadata.has_registered) {
      setOpenModal(true);
    }

    if (user && user.sub) {
      pendo.initialize({
        visitor: {
          id: user.sub,
        },
      });
    }
  }, [user]);

  if (isLoading) {
    return <></>; // Could replace this with some kind of loading screen or take it into account in components but a blank will only be displayed for a very brief period of time
  }

  if (!isAuthenticated) {
    return <Navigate replace to={"/login"} />;
  }

  return (
    <div className={s.layout}>
      <section
        className={c(s.left, { [s.hasMessage]: chatHistory.length > 0 })}
      >
        <ModelSelect />
        <Posters />
        <UserPanel />
        <Footer />
      </section>

      <section className={s.right}>
        <ChatArea />
        <QuestionInput />
      </section>

      {openModal && (
        <RegisterBodal
          setIsOpen={setOpenModal}
          firstName={user?.given_name}
          lastName={user?.family_name}
        />
      )}
    </div>
  );
};

export default Home;
