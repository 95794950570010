import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import callLakituForBoclipsToken from "../api/callLakituForBoclipsToken";

export interface BoclipsToken {
  access_token: string;
  expiry?: number;
}

const isTokenValid = (boclipsToken?: BoclipsToken): boolean => {
  return (
    !!boclipsToken?.access_token &&
    (!boclipsToken?.expiry || boclipsToken?.expiry - Date.now() > 5000)
  );
};

interface ContextProps {
  aiModelForLakitu: string | null;
  setAiModelForLakitu: React.Dispatch<React.SetStateAction<string>>;
  aiModelForRender: string | null;
  setAiModelForRender: React.Dispatch<React.SetStateAction<string>>;
  conversationId: string | null;
  setConversationId: (id: string) => void;
  chatHistory: ChatHistory[];
  setChatHistory: React.Dispatch<React.SetStateAction<ChatHistory[]>>;
  auth0Token: string;
  boclipsToken?: BoclipsToken;
  lakituError?: ErrorResponse;
  setLakituError: React.Dispatch<
    React.SetStateAction<ErrorResponse | undefined>
  >;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ModelObject {
  id: string;
  label: string;
  description: string;
}

export interface ErrorResponse {
  detail: string;
}

interface Props {
  children: React.ReactNode;
}

export interface ChatHistory {
  role: string;
  content: string;
  clips?: ChatResponseItem | null;
}

export interface ChatResponseItem {
  [key: string]: ChatItem;
}

export interface ChatItem {
  channel: string;
  clip_duration: number;
  clip_name: string;
  end_time: number;
  start_time: number;
  video_id: string;
  clip_id?: string;
}

export interface ChatResponse {
  answer: string;
  clips: ChatResponseItem | null;
  conversationId: string;
}

const tutorContext = createContext<ContextProps>({
  aiModelForLakitu: null,
  setAiModelForLakitu: () => null,
  conversationId: null,
  chatHistory: [],
  setChatHistory: () => null,
  setConversationId: () => null,
  auth0Token: "",
  lakituError: undefined,
  setLakituError: () => null,
  aiModelForRender: null,
  setAiModelForRender: () => null,
  isLoading: false,
  setIsLoading: () => null,
});

function AiTutorContextProvider({ children }: Props) {
  const [aiModelForLakitu, setAiModelForLakitu] = useState<string>("");
  const [aiModelForRender, setAiModelForRender] = useState<string>("");
  const [chatHistory, setChatHistory] = useState<ChatHistory[]>([]);
  const [lakituResponse, setLakituResponse] = useState<ChatResponse[]>([]);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [auth0Token, setAuth0Token] = useState("");
  const [boclipsToken, setBoclipsToken] = useState<BoclipsToken | undefined>(
    undefined,
  );
  const [lakituError, setLakituError] = useState<ErrorResponse | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleGetBoclipsTokenFromLakitu = async () => {
    if (!isTokenValid(boclipsToken)) {
      try {
        const token = await callLakituForBoclipsToken(auth0Token);

        if (!token.access_token) return;

        const expiry = token.expires_in
          ? 1000 * parseInt(token.expires_in) + Date.now()
          : undefined;

        setBoclipsToken({
          access_token: token.access_token,
          expiry: expiry,
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/login") return;

    async function getAuth0Token() {
      try {
        const response = await getAccessTokenSilently();
        setAuth0Token(response);
      } catch (e) {
        // @ts-ignore
        setLakituError({ detail: e.message });
      }
    }

    getAuth0Token();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (!auth0Token) {
      return;
    }
    handleGetBoclipsTokenFromLakitu();
  });

  const context = useMemo(
    () => ({
      aiModelForLakitu,
      setAiModelForLakitu,
      aiModelForRender,
      setAiModelForRender,
      conversationId,
      setConversationId,
      chatHistory,
      setChatHistory,
      lakituResponse,
      setLakituResponse,
      auth0Token,
      boclipsToken,
      lakituError,
      setLakituError,
      isLoading,
      setIsLoading,
    }),
    [
      aiModelForLakitu,
      aiModelForRender,
      chatHistory,
      lakituResponse,
      conversationId,
      auth0Token,
      boclipsToken,
      lakituError,
      isLoading,
      setIsLoading,
    ],
  );

  return (
    <tutorContext.Provider value={context}>{children}</tutorContext.Provider>
  );
}

function useAiTutorContextProvider() {
  const {
    aiModelForLakitu,
    setAiModelForLakitu,
    aiModelForRender,
    setAiModelForRender,
    chatHistory,
    setChatHistory,
    conversationId,
    setConversationId,
    auth0Token,
    boclipsToken,
    lakituError,
    setLakituError,
    isLoading,
    setIsLoading,
  } = useContext(tutorContext);

  return {
    aiModelForLakitu,
    setAiModelForLakitu,
    aiModelForRender,
    setAiModelForRender,
    conversationId,
    setConversationId,
    chatHistory,
    setChatHistory,
    auth0Token,
    boclipsToken,
    lakituError,
    setLakituError,
    isLoading,
    setIsLoading,
  };
}

export { AiTutorContextProvider, useAiTutorContextProvider, isTokenValid };
