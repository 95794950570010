import React, { useEffect, useState } from "react";
import callLakituForModels from "../../api/callLakituForModels";
import {
  useAiTutorContextProvider,
  ModelObject,
} from "../../context/aiTutorContextProvider";
import Dropdown, { OptionsProps } from "@boclips-ui/dropdown";
import s from "./style.module.less";
import { Typography } from "@boclips-ui/typography";
import { Link } from "react-router-dom";
import logo from "../../assets/boclips-logo.png";

const ModelSelect = () => {
  const [modelOptions, setModelOptions] = useState<ModelObject[]>([]);

  const { aiModelForLakitu, setAiModelForLakitu, auth0Token } =
    useAiTutorContextProvider();

  useEffect(() => {
    if (auth0Token) {
      callLakituForModels(auth0Token)
        .then((models) => {
          setModelOptions(models);
          setAiModelForLakitu(models[0].id);
        })
        .catch(() => {
          setModelOptions([]);
        });
    }
  }, [auth0Token]);

  const options: OptionsProps[] = modelOptions?.map((it) => ({
    id: it.id,
    name: it.label,
    value: it.id,
    label: (
      <div className={s.dropdownItem}>
        <Typography.Body weight="medium" as="span">
          {it.label}
        </Typography.Body>
        <Typography.Body as="span">{it.description}</Typography.Body>
      </div>
    ),
  }));

  return (
    <div className={s.modelSelect}>
      <Link reloadDocument to="/">
        <img src={logo} className={s.logo} alt="boclips logo" />
      </Link>
      <Typography.Body size="small">Boclips AI model:</Typography.Body>

      <Dropdown
        mode={"single"}
        options={options}
        fitWidth
        placeholder={"Select your model"}
        defaultValue={aiModelForLakitu || ""}
        onUpdate={(value) => {
          setAiModelForLakitu(value as string);
        }}
      />
    </div>
  );
};

export default ModelSelect;
