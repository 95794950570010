import s from "./style.module.less";
import { Typography } from "@boclips-ui/typography";
import React from "react";
import { useAiTutorContextProvider } from "../../context/aiTutorContextProvider";
import UserProfile from "../userProfile";
import LogoutButton from "../buttons/logout";

const UserPanel = () => {
  const { conversationId } = useAiTutorContextProvider();

  return (
    <div className={s.logoutWrapper}>
      {conversationId && (
        <Typography.Body as="span" size="small">
          Conversation ID: {conversationId}
        </Typography.Body>
      )}
      <div>
        <UserProfile />
        <LogoutButton />
      </div>
    </div>
  );
};

export default UserPanel;
