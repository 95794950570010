// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer--WSJOW {
  padding: 1rem;
  border-top: 2px solid #D9E3FF;
  order: 4;
  text-align: center;
  color: #1A202C;
}
@media (max-width: 768px) {
  .footer--WSJOW {
    width: 100%;
  }
}
.link--iWUxE {
  position: relative;
  font-weight: 500 !important;
  display: block;
  text-align: center;
  margin-top: 0.25rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,6BAAA;EACA,QAAA;EACA,kBAAA;EACA,cAAA;AADF;AAGE;EAAA;IACE,WAAA;EAAF;AACF;AAGA;EACE,kBAAA;EACA,2BAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;AADF","sourcesContent":["@import \"~@boclips-ui/styles/src/colors\";\n\n.footer {\n  padding: 1rem;\n  border-top: 2px solid @blue-400;\n  order: 4;\n  text-align: center;\n  color: @gray-900;\n\n  @media (max-width: 768px) {\n    width: 100%;\n  }\n}\n\n.link {\n  position: relative;\n  font-weight: 500 !important;\n  display: block;\n  text-align: center;\n  margin-top: 0.25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `footer--WSJOW`,
	"link": `link--iWUxE`
};
export default ___CSS_LOADER_EXPORT___;
