import React from "react";
import s from "./style.module.less";
import LoginButton from "../../components/buttons/login";
import logo from "../../assets/boclips-logo.png";
import { Typography } from "@boclips-ui/typography";
import Table from "../../assets/happy-guy.svg";
import features from "./helpers/features";

const LoginPage = () => {
  return (
    <div className={s.loginMainContainer}>
      <div className={s.hero}>
        <Typography.H1 size={{ mobile: "md", desktop: "lg" }}>
          Introducing video <span>highlights</span>:
          <br /> Elevate your AI learning experience with Boclips
        </Typography.H1>

        {features.map(({ description }, index) => {
          return (
            <section key={index} className={s.featureInfo}>
              <section>
                <Typography.Body as="div" size="small">
                  {description}
                </Typography.Body>
              </section>
            </section>
          );
        })}
      </div>

      <div className={s.loginContainer}>
        <div className={s.logo}>
          <img src={logo} alt="Boclips logo" />
        </div>
        <Table className={s.loginIllustration} />

        <div className={s.actions}>
          <Typography.Body>
            Explore video highlights in beta via a chatbot
          </Typography.Body>
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
