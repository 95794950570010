// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.posters--gr6ip {
  overflow: auto;
  flex-grow: 1;
  padding: 1rem;
  color: #1A202C;
}
.posters--gr6ip aside {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  border: 2px solid #00217D;
  background: #FFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
.posters--gr6ip aside button {
  margin-bottom: 0.5rem;
}
.posters--gr6ip aside p {
  margin-top: 0;
}
@media (max-width: 768px) {
  .posters--gr6ip {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    order: 3;
  }
  .posters--gr6ip.hide--Mc0Xv {
    display: none;
  }
  .posters--gr6ip aside {
    height: -moz-fit-content;
    height: fit-content;
  }
  .posters--gr6ip * {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/posters/style.module.less"],"names":[],"mappings":"AAGA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;AAFF;AAFA;EAOI,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,gBAAA;EACA,2CAAA;EACA,oBAAA;EACA,mBAAA;AAFJ;AAbA;EAkBM,qBAAA;AAFN;AAhBA;EAsBM,aAAA;AAHN;AAQA;EACE;IACE,aAAA;IACA,mBAAA;IACA,WAAA;IACA,WAAA;IACA,QAAA;EANF;EAQE;IACE,aAAA;EANJ;EAFA;IAYI,wBAAA;IAAA,mBAAA;EAPJ;EAUE;IACE,eAAA;EARJ;AACF","sourcesContent":["@import \"~@boclips-ui/styles/src/colors\";\n@box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);\n\n.posters {\n  overflow: auto;\n  flex-grow: 1;\n  padding: 1rem;\n  color: @gray-900;\n\n  aside {\n    width: 100%;\n    border-radius: 4px;\n    display: flex;\n    flex-direction: column;\n    border: 2px solid @blue-800;\n    background: @white;\n    box-shadow: @box-shadow;\n    padding: 0.5rem 1rem;\n    margin-bottom: 1rem;\n\n    button {\n      margin-bottom: 0.5rem;\n    }\n\n    p {\n      margin-top: 0;\n    }\n  }\n}\n\n@media (max-width: 768px) {\n  .posters {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    gap: 0.5rem;\n    order: 3;\n\n    &.hide {\n      display: none;\n    }\n\n    aside {\n      height: fit-content;\n    }\n\n    & * {\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"posters": `posters--gr6ip`,
	"hide": `hide--Mc0Xv`
};
export default ___CSS_LOADER_EXPORT___;
