import { Typography } from "@boclips-ui/typography";
import React from "react";

const features = [
  {
    description:
      "ChatGPT and Gemini aren't focused on delivering videos. But for education, videos are a critical tool to drive engagement and create accessible learning opportunities.",
  },

  {
    description: (
      <>
        So we&apos;ve developed{" "}
        <a
          href="https://boclips.com/waitlist"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography.Link type="inline-blue">video highlights</Typography.Link>
        </a>{" "}
        to enhance chatbots for education with the right learning moment from
        our library of 2.2M Ed-Ready videos.
      </>
    ),
  },

  {
    description:
      "Chat with our bot and see how video highlights can transform your AI Tutor and genAI experiences.",
  },
];

export default features;
