import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@boclips-ui/button";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      onClick={() =>
        logout({
          logoutParams: { returnTo: `${window.location.origin}/login` },
        })
      }
      text="Log Out"
      height="41px"
    />
  );
};

export default LogoutButton;
