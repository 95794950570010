import { ChatHistory, ChatResponse } from "../context/aiTutorContextProvider";

interface Props {
  request: {
    chatHistory: ChatHistory[];
    conversation_id: string | null;
    feedback_message: string;
    clip_id: string;
    selected_option_id: string;
  };
  auth0Token: string;
}

async function sendVideoFeedback({
  request,
  auth0Token,
}: Props): Promise<ChatResponse> {
  const API_URL = process.env.LAKITU_SEND_VIDEO_FEEDBACK_URL!;
  const headers = {
    accept: "application/json",
    authorization: `Bearer ${auth0Token}`,
    "Content-Type": "application/json",
  };

  const history = request.chatHistory.map((it) => ({
    role: it.role,
    content: it.content,
  }));

  const body = {
    chat_history: history,
    conversation_id: request.conversation_id,
    feedback_message: "",
    clip_id: request.clip_id,
    selected_option_id: request.selected_option_id,
    agent_reply: history[history.length - 1].content,
  };

  const response = await fetch(API_URL, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw Error("api call error " + response.status);
  }

  return response.json();
}

export default sendVideoFeedback;
